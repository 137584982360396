import "./public-path.js";
import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import "./tailwind.scss";

export { connectAccountModal } from "./settings/account-wizard/externally-exported-connect-account.component.js";
export { sendBulkEmail } from "./bulk-email/send-bulk-email.js";
export { sanitizeRichTextHtml } from "src/common/sanitize/rich-text/bandicoot-html-sanitizer.js";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  loadRootComponent: () =>
    import(/* webpackChunkName: "communications-app" */ "./root.component.js").then((md) => md.default),
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
});

export const bootstrap = [canopyLifecycles.bootstrap, reactLifecycles.bootstrap];

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

function domElementGetter() {
  let el = document.getElementById("communications-ui");
  if (!el) {
    el = document.createElement("div");
    el.setAttribute("id", "communications-ui");
    document.body.insertBefore(el, document.body.childNodes[0]);
  }
  return el;
}

export function getComposeEmailOverlay() {
  return import(/*webpackChunkName: "compose-email-overlay" */ "./exports/compose-email.component.js").then(
    (mod) => mod
  );
}

export async function getEmailInfo() {
  return await import(/*webpackChunkName: "email-info" */ "./exports/email-info.component.js");
}

export async function getCommentFeed() {
  return await import(/*webpackChunkName: "comment-feed" */ "./exports/comment-feed-export.component.js");
}

export async function getEmailThreadOverlay() {
  return await import(/*webpackChunkName: "email-thread-overlay" */ "./exports/email-thread-overlay.component.js");
}

export async function getReauthenticationDrawer() {
  return await import(
    /* webpackChunkName: "reauthentication-drawer" */ "./reauthentication/reauthentication-drawer.component.js"
  );
}
